import React, { Fragment } from "react"
import { Styled } from "theme-ui"

/**
 * Change the content to add your own bio
 */

export default () => (
  <Fragment>
    Kalle Lamminpää
    <br />
    Test
  </Fragment>
)
